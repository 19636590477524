/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/style/styles.scss"
import "./src/components/functions.js"
import React from "react";
 import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
 
 const reCaptchaKey = '6LcC2AYrAAAAAPNjWDIsMhEGSGQCL8nqQwsRriRa'
 export const wrapRootElement = ({ element }) => (
   <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
     {element}
   </GoogleReCaptchaProvider>
 );